<template>
    <div class="home">
      <div style="display:flex;position: fixed; 
    width: 100%;
    background-color: #fff;
    z-index: 999;">
        <el-menu default-active="/comment/commentedit" class="el-menu-demo" mode="horizontal" @select="handleSelect">
          <el-menu-item index="/comment/commentedit">审核详情</el-menu-item>
          <el-menu-item index="/comment/commentexaminelist">审核记录</el-menu-item>
        </el-menu> 
        <div style="
            height: 60px;
            line-height: 60px;
            right: 42px;
    position: fixed;"><i @click="handleSelect('/comment/commentlist')" class="el-icon-close"></i></div>
      </div>

      <div class="pt20"></div>
      <div>
      <div class="jiben" style="margin-top: 45px; ">
       <div style="display: flex;" class="bgl"> <h4 style="width: 65px;line-height: 28px;margin: 0;">基本信息</h4>
       <el-button class="mj" @click="shouqi" v-show="btnshow" size="mini" type="info">展开</el-button>
       <el-button class="mj" @click="shouqi" v-show="!btnshow" size="mini" type="info">收起</el-button>
        </div>
        <div class="fle" v-show="!btnshow">
          <div>
            <div>账户的手机号：<input style="width: 250px;" type="text" disabled v-model="form.base_data.phone"></div>  
            <div>审核订单编号：<input style="width: 250px;" type="text" disabled v-model="form.base_data.no"></div>   
          </div>

          <div>
            <div>提交时间：<input type="text" disabled v-model="form.base_data.t_data"></div> 
            <div>审核状态：<input type="text" disabled v-model="form.base_data.status_text"></div> 
          </div>
          
          <div>
            <div>用户编号：<input type="text" disabled v-model="form.base_data.uid"></div>
          </div>
        </div>
        <div style="display: flex;" class="bgl">
          <h4 style="line-height: 28px;margin: 0;">针对对象信息</h4>
          <el-button class="mj" @click="shouqi2" v-show="btnshow2" size="mini" type="info">展开</el-button>
          <el-button class="mj" @click="shouqi2" v-show="!btnshow2" size="mini" type="info">收起</el-button>
        </div>
        <div class="fle" v-show="!btnshow2">
          <div>
            <div>类型：<input type="text" :title="form.re.type" style="width:20 0px;" disabled v-model="form.re.type"></div>
            <div>内容：</div>
            <div><textarea type="text" style="width:500px;min-height:100px;" disabled v-model="form.re.content"></textarea></div>
          </div>
        </div>
        <h4 class="bgl">待审核内容</h4>
        <div class="fle">
          <div>
          <div>正文：</div>
          <div><textarea type="text" style="width:500px;min-height:100px;" disabled v-model="form.info.content"></textarea></div>
          </div>
        </div>
        <h4>审核处理信息</h4>
      </div>
      
    <div class="form">
      <el-form ref="form" :model="form">
        <el-form-item :required="true" label="审核结果">
          <el-select 
             v-model="form.status" :disabled='form.ex_data.status==3' placeholder="请选择">
            <el-option
              v-for="item in opstatus"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :required="true" v-if="form.status == 3" label="违规程度">
          <el-select :disabled='form.ex_data.status==3'  v-model="form.violation" placeholder="请选择">
            <el-option
              v-for="item in opviolation"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :required="true" v-if="form.status == 3" label="未通过的原因">
          <div v-if='form.ex_data.status==3' >
            <el-checkbox v-for="reason in form.ex_data.reason" checked disabled :label="reason" :key="reason.id">{{reason.name}}</el-checkbox>
          </div>
          <div v-if='form.ex_data.status!=3' >
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
            <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
              <el-checkbox v-for="city in cities" :label="city" :key="city.id">{{city.name}}</el-checkbox>
              <el-input  style="width: 400px;" maxlength="12" v-model="zidingyi" placeholder="请输入12以内说明"></el-input>
            </el-checkbox-group>
          </div>
        </el-form-item>
        <el-form-item :required="false" label="备注">
          <el-input
            type="textarea"
            :autosize="{ minRows: 5, maxRows: 10}"
            placeholder="请输入内容"
            v-model="form.remark">
          </el-input>
        </el-form-item>
      <el-form-item>
        <el-button type="primary" v-if="form.ex_data.status != 3 && !look" style="margin-left: 45%;" @click="onSubmit">提交</el-button>
      </el-form-item>
    </el-form>
</div>
</div>
      <div class="pt20"></div>
    </div>
</template>
<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
export default {
  name: 'Home',
  components: {
    
  },
  data(){
    return {
        checkAll: false,
        checkedCities: [],
        cities: [],
        isIndeterminate: true,
      drawer: false,
      pl_examine_id:0,
      look:false,
      form: {
        base_data:{
          phone:'',
          t_data:'',
          no:'',
          status_text:'',
        },
          re:{
              content:'',
          },
          info:{
              content:'',
          },
          ex_data:{
            status: 1,
            status_text: '',
            violation: '',
            violation_text: '',
            reason: [],
            remark: '',
          }
        },
        options:[],
        opstatus:[
          {
            value: '2',
            label: '审核通过'
          },
          {
            value: '3',
            label: '审核未通过'
          }
        ],
        opviolation:[
          {
            value: '1',
            label: '轻度'
          },
          {
            value: '2',
            label: '重度'
          },
          {
            value: '3',
            label: '删除'
          }
        ],
        zidingyi:'',
        btnshow:false,
        btnshow2:false
    }
  },
  methods: {
      handleSelect(url){
        this.$router.push(url)
      },
      handleCheckAllChange(val) {
        this.checkedCities = val ? [{id:1,name:'含有敏感词'},{id:2,name:'涉及恶意攻击'},{id:3,name:'包含广告'},{id:4,name:'自定义'}] : [];
        this.isIndeterminate = false;
      },
      handleCheckedCitiesChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.cities.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
      },
     onSubmit() {
      let _this = this;
      let postdata = {};
      this.form.pl_examine_id = this.pl_examine_id;
      let cities = this.checkedCities;
        for(let i in cities){
          if(cities[i].name == '自定义'){
            cities[i].name = _this.zidingyi;
          }
        }
      this.form.reason = cities;
      if(_this.form.status == 3){
        if(!_this.form.violation){
          _this.$message.error('违规程度必选');
            return;
        }
        postdata.violation = _this.form.violation;
        if(cities.length == 0){
          _this.$message.error('违规原因必选');
            return;
        }
        postdata.reason = JSON.stringify(_this.form.reason);
      }else{
        postdata.reason = '[]';
        postdata.violation = 0;
      }
        if(_this.form.remark){
          postdata.remark = _this.form.remark;
        }else{
          postdata.remark = '';
        }
        if(!_this.form.status){
            _this.$message.error('请填写必填项');
            return;
        }
        postdata.status = _this.form.status;
        postdata.pl_examine_id = _this.form.pl_examine_id;
        postdata.updated_at = _this.form.updated_at;
      axios.post(config.commentExamine,postdata)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                message: response.data.message,
                type: 'success'
              });
              _this.goNav('/comment/commentlist');
            }else{
                _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    },
      goNav(url){
        this.$router.push(url)
      },
      shouqi(){
        this.btnshow = !this.btnshow;
      },
      shouqi2(){
        this.btnshow2 = !this.btnshow2;
      },
    init(a){
        let _this = this;
        let obj = JSON.parse(a);
        _this.look = obj.look;
        this.pl_examine_id = obj.pl_examine_id;
        axios.get(config.commentExamineInfo,{params:{pl_examine_id:obj.pl_examine_id}})
        .then(function (response) {
            if(response.data.code == 200){
                _this.form = response.data.data;
                _this.cities = [{id:1,name:'含有敏感词'},{id:2,name:'涉及恶意攻击'},{id:3,name:'包含广告'},{id:4,name:'自定义'}];
                if(_this.form.ex_data.status == 3){
                  _this.form.status = _this.form.ex_data.status + '';
                  _this.form.violation = _this.form.ex_data.violation + '';
                }
            }else{
                _this.$message.error(response.data.message);
                _this.$router.push('/comment/commentlist');
            }
          })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
  created(){
     let a = sessionStorage.getItem('edit');
      this.init(a);
    
  }
}
</script>
<style scoped>
.form{
    width: 500px;
}
.jiben{
    text-align: left;
}
.jiben div{
  
    vertical-align: middle;
    font-size: 14px;
    color: #606266;
    line-height: 25px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
}
.fle{
  display: flex;
}
.fle div{
    vertical-align: middle;
    font-size: 14px;
    color: #606266;
    line-height: 25px;
    padding: 0 18px 10px 0;
    box-sizing: border-box;
    font-weight: bold;
}
.fle div input{
  height: 20px;
}
.mj{
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 10px;
}
.el-menu--horizontal > .el-menu-item.is-active{
    font-weight: bold;
}
.el-icon-close:before{
  font-size: 24px;
}
.el-menu-item{
  font-size: 16px;
}
.jiben .bgl{
    background-color: aliceblue;
    padding: 10px;
    margin-bottom: 5px;
}
</style>